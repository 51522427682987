import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      Made by{"  "}
      <a href="https://github.com/ismailcet" className="ismailcet">
        Ismailcet
      </a>{" "}
    </div>
  );
};

export default Footer;
